import { HostListener, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
selector: 'app-root',
templateUrl: './app.component.html',
styleUrls: ['./app.component.css']
})
export class AppComponent {
title = 'my-dream-app';
heightv=0;
pageFlag=true;
product_img=[];
project_title="";
technolgy="";
project_url="";


@ViewChild('myIdentifier') myIdentifier:any= ElementRef;

@HostListener('window:scroll') onScroll(e: Event): void {
this.heightv = document.documentElement.scrollTop;
}

  slides = [
    {
      message:" The MCS team communicated on time and perform the task with professionalism. I recommend this team to others.", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Kalanda Scarvers",
      company_name:"CEO, iTechnical Corporation"
    }, {
      message:"very responsive and good quality work.Will use again. ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Michael Graham Hyde",
      company_name:"Pearl Services Co. Ltd."
    },
    {
      message:"I have never met someone who is very dedicated to his work and clients he an exemplary of what a great Team contractor is.Thankyou for your commitment on this task. ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Phil Muhire",
      company_name:"Upwork Client"
    },
    {
      message:"Professional Work ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Jena Karlis",
      company_name:"Direct Client"
    },
    {
      message:"The MCS team is very cool team, they understands what is required and provide the right solution. Choose them for the better product with in budget too. ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Naveen K.",
      company_name:"Direct Client"
    },{
      message:"Top class Team. They did my project quickly. I highly recommend them. ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Seema S.",
      company_name:"Direct Client"
    },

    {
      message:"Really nice and cooperative person. Surely work with him again. ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Eplanet Soft",
      company_name:"Direct Client"
    },

    {
      message:"Great Team and defiantly will hire for future jobs. A+++ ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Ayaz M.",
      company_name:"Direct Client"
    },
    {
      message:"Completed Difficult project for very demanding client, Handled pressure and dynamic changing requirements well an dwill continue to use again in future ", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Mark D.",
      company_name:"Direct Client"
    },
   
    {
      message:"good Developer. he is always on time for the development work", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Krishna K.",
      company_name:"Direct Client"
    },
    {
      message:"He is an expert.. Excellent work done by the team.", 
      img: "assets/img/testimonials/testimonial-icon.png",
      name:"Narendra Babu N.",
      company_name:"Direct Client"
    },

  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};




  slideConfigg = {"slidesToShow": 1, "slidesToScroll": 1,"dots":true, "autoplay": true}; 





  page(name:any){
      /* alert(name); */
      console.log(name)
      document.getElementById(name)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  pageShow(img:any,project_title:any,technolgy:any,project_url:any){
    this.pageFlag =false;
    this.product_img = img.split(',');
    this.project_title = project_title;
    this.technolgy=technolgy;
    this.project_url=project_url;
    window.scroll({ 
      top: 0, 
      left: 0
    });
  }

  close(){
    this.pageFlag =true;
    this.product_img = [];
    this.project_title = '';
    this.technolgy='';
    this.project_url=''
  }
}


/* 
toAbout(){
document.getElementById("about")?.scrollIntoView({behavior:"smooth"});
}

toServices(){
  document.getElementById("services")?.scrollIntoView({behavior:"smooth"});
  }

toPortfolio(){
  document.getElementById("portfolio")?.scrollIntoView({behavior:"smooth"});
  }

toContact(){
  document.getElementById("contact")?.scrollIntoView({behavior:"smooth"});
  } */