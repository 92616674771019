
  <!-- ======= Header ======= -->
  
  <!-- ======= Hero Section ======= -->
  <section id="hero" *ngIf="pageFlag">
    <div class="hero-container">
      <a href="index.html" class="hero-logo" data-aos="zoom-in"><img src="assets/img/crowlogo.png" alt=""></a>
      <h1 data-aos="zoom-in">Welcome To Mettle Crow Solutions</h1>
      <h2 data-aos="fade-up">DELIVERING THE BEST IT SERVICES, WORLDWIDE</h2>
      <a (click)= "page('about1')" data-aos="fade-up" href="#about" class="btn-get-started scrollto">Get Started</a>
    </div>
  </section> <!-- End Hero -->
  



    <!-- ======= Header ======= -->
    

 <div  id="header" class="d-flex align-items-center myIdentifier" *ngIf="heightv >= 700" style="width: 1519.2px;
  position: fixed;
  top: 0px;
  z-index: 50; width:100%;"> 

      <div class="container">
  
        <!-- The main logo is shown in mobile version only. The centered nav-logo in nav menu is displayed in desktop view  -->
        <div class="logo d-block d-lg-none">
        
          <a href="index.html"><img src="assets/img/crowlogo.png" alt="" class="img-fluid"></a>
  
        </div>
  
        <nav class="nav-menu d-none d-lg-block">
          <ul class="nav-inner">
  
            <li class="nav-logo"><a href="index.html"><img src="assets/img/crowlogo.png" alt="" class="img-fluid"></a>
            </li>
  
            <li class="active"><a href="#hero">Home</a></li>
            <li (click)="page('about1')"><a href="#about1">About</a></li>
            <li (click)="page('services1')"><a href="#services1">Services</a></li>
            <li (click)="page('portfolio1')"><a href="#portfolio1">Portfolio</a></li>
            <li (click)="page('contact1')"><a href="#contact1">Contact</a></li>
  
          </ul>
        </nav><!-- .nav-menu -->
  
      </div>
    </div><!-- End Header -->

  <main id="main" *ngIf="pageFlag">

    <!-- ======= About Us Section ======= -->
    <section id="about1" class="about">
      <div class="container">

        <div class="section-title mb-5" data-aos="fade-up">
          <h2>About Us</h2>
          <p>We are a team of Highly motivated and dedicated individual programmers recognized for our achievements in
            Software Development arena.</p>
        </div>

        <div class="row">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="image setimg">
              <img src="assets/img/about.jpg" class="img-fluid" alt="">
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="content pt-4 pt-lg-0 pl-0 pl-lg-3 ">
              <h3></h3>
              <p class="font-italic">
                Mettle Crow Solutions is a young, ambitious and trusted global technology software development company
                in India that designs, builds and implements effective software technology solutions supplying the needs
                of your business.
              </p>
              <ul>
                <li><i class="bx bx-check-double"></i> We have come together to share our years of experience combined
                  with our proven expertise in technology and how technology can be used to help meet the needs of
                  businesses of any size.</li>
                <li><i class="bx bx-check-double"></i> We at Mettle Crow Solutions cover all aspects of software product
                  development using the latest technologies and frameworks, and quickly adjust to customer’s demands.
                  Our approach combines research, design, and development to rapidly solve problems, create and test
                  prototypes and launch a product onto the market in an iterative way.

                <li><i class="bx bx-check-double"></i> We offer a broad range of full-cycle Software Development
                  delivering innovative solutions with a proven track record in Product Development, Technology
                  Consulting and IT Outsourcing. Our User Experience, Complex Engineering, and Agile Delivery give you
                  the best software product for better business outcomes.</li>
              </ul>

            </div>
          </div>
        </div>

      </div>
    </section><!-- End About Us Section -->

    <!-- ======= Services Section ======= -->
    <section id="services1" class="services mb-5">
      <div class="container">

        <div class="section-title mb-5" data-aos="fade-up">
          <h2>Services</h2>
          <p>Mettle Crow Solutions is all geared up and updated to pace up with the changing environment.</p>
        </div>

        <div class="row">
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="icon-box mt-5 mt-lg-0" data-aos="fade-up">
              <i class="bx bx-receipt"></i>
              <h4>WEB DESIGNING</h4>
              <p>We tend to ensure that we offer the web services for customer’s satisfaction by creating a unique and
                sleek design so, that it gives the client simply the sort of look he needs. Your advancement is our aim.
              </p>
            </div>
            <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="100">
              <i class="bx bx-cube-alt"></i>
              <h4>WEB DEVELOPMENT</h4>
              <p>We work on Latest platforms in Web Development like, ReactJS, NodeJS, WordPress, Angular, Dot Net, MVC,
                Core, PHP frameworks - Laravel, CodeIgniter, CakePHP, Yii, MongoDb, MySql, NoSql , firebase, and lot
                others.</p>
            </div>
            <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="200">
              <i class="bx bx-images"></i>
              <h4>WINDOWS SOFTWARE DEVELOPMENT</h4>
              <p>We do windows software development in C# , Dotnet. We develop software like users activity tracker,
                schools management system, Quick books management, Timer based applications, etc</p>
            </div>
            <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="300">
              <i class="bx bx-mobile"></i>
              <h4>MOBILE DEVELOPMENT</h4>
              <p>Mobiles have revolutionized our life, our day to day activities, be it our social involvement, online
                shopping, advertising or gaming experience. Mobile application development techniques are dynamic and
                ever changing and our team at Mettle Crow Solutions is all geared up and updated to pace up with the
                changing environment.</p>
            </div>



          </div>
          <div class="image col-lg-6 order-1 order-lg-2" style='background-image: url("assets/img/services.jpg");'
            data-aos="fade-left" data-aos-delay="100"></div>
        </div>

      </div>
    </section><!-- End Services Section -->




    <!-- ======= Featured Section ======= -->
    <section id="featured1" class="featured">
      <div class="container">
      <div class="section-title mb-5" data-aos="fade-up">
          <h2>Why Choose Us?</h2>
          <p>We believe in maintaining long term work relationship with our clients so to provide quality work.</p>
      </div>
        <div class="row">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <figure>
                  <img src="assets/img/featured-1.png" alt="" class="img-fluid">
                </figure>
              </div>
              <div class="tab-pane" id="tab-2">
                <figure>
                  <img src="assets/img/featured-2.png" alt="" class="img-fluid">
                </figure>
              </div>
              <div class="tab-pane" id="tab-3">
                <figure>
                  <img src="assets/img/featured-3.png" alt="" class="img-fluid">
                </figure>
              </div>
              <div class="tab-pane" id="tab-4">
                <figure>
                  <img src="assets/img/featured-4.png" alt="" class="img-fluid">
                </figure>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-4 mt-lg-0" data-aos="fade-left">
            <ul class="nav nav-tabs flex-column">
              <li class="nav-item">
                <a class="nav-link active show" data-toggle="tab" href="#tab-1">
                  <h4>Latest Technologies Development</h4>
                  <p>We do work in latest technologies and frameworks to ensure performance oriented development.</p>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-toggle="tab" href="#tab-2">
                  <h4>Market Standard Code</h4>
                  <p>We do market standard code for quality work. We believe that market standard code always requires less maintenance of application and helps application to run in a bug free state.</p>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-toggle="tab" href="#tab-3">
                  <h4>Zero Bug Deliverables</h4>
                  <p>We do unit testing of each module of application before delivery to ensure that zero bugs application is delivered to the client for hassle free usage.</p>
                </a>
              </li>
       
            </ul>
          </div>
        </div>
      </div>
    </section><!-- End Featured Section -->

    <!-- ======= Why Us Section ======= -->
    <section id="why-us1" class="why-us">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-stretch">

            <div class="content" data-aos="fade-up">
              <h3>We use Agile Methodology for a <strong>quality output. </strong></h3>
              <p>
              
              </p>
            </div>

            <div class="accordion-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
              
                    <p>
                      Agile software development is an approach to software development under which requirements and solutions 
                      evolve through the collaborative effort of self-organizing and cross-functional teams and their 
                      customer(s)/end user(s). It advocates adaptive planning, evolutionary development, early delivery, and 
                      continual improvement, and it encourages rapid and flexible response to change.
                    </p>
                  
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
              
                    <p>
                      Compared to traditional software engineering, agile software development mainly targets complex systems and 
                      product development with dynamic, non-deterministic and non-linear characteristics. Accurate estimates, stable plans, 
                      and predictions are often hard to get in early stages, and confidence in them is likely to be low.Agile practitioners will seek to reduce the leap-of-faith that is needed before any evidence of value can be obtained. 
                      Requirements and design are held to be emergent. Big up-front specifications would probably cause a lot of waste in such cases, i.e., are not economically sound.
                    </p>
             
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
              
                    <p>
                      These basic arguments and previous industry experiences, learned from years of successes and failures, 
                      have helped shape agile development’s favor of adaptive, iterative and evolutionary development.
                    </p>
                 
                </li>

              </ul>
            </div>

          </div>

          <div class="col-lg-5 order-1 order-lg-2 align-items-stretch video-box"
            style='background-image: url("assets/img/agilenew.png");' data-aos="zoom-in">
            <!-- <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video"
              data-autoplay="true"></a> -->
          </div>

        </div>

      </div>
    </section><!-- End Why Us Section -->


    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio1" class="portfolio">
      <div class="container">

        <div class="section-title mb-5" data-aos="fade-up">
          <h2>Portfolio</h2>
          <p>Some of our Recent Projects.We here to create meaningful and lasting relationships with our clients.</p>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center">
           <!--  <ul id="portfolio-flters">
              <li data-filter=".filter-app" class="filter-active">Projects</li>
            </ul> -->
          </div> 
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app"  (click)="pageShow('assets/img/portfolio/fairsquare1.png,assets/img/portfolio/fairsquare1.png,assets/img/portfolio/fairsquare1.png','Fair Square','Vue Js','https://www.fairsquare.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/fairsquare.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>FAIR SQUARE</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/fairsquare.png','Fair Square','Vue Js','https://www.fairsquare.com/')"href="assets/img/portfolio/fairsquare.png" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>


          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/grouppublishing1.png,assets/img/portfolio/grouppublishing1.png,assets/img/portfolio/grouppublishing1.png','Group Publishing','PHP','https://www.group.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/grouppublishing.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>Group Publishing</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/grouppublishing.png','Group Publishing','PHP','https://www.group.com/')" href="assets/img/portfolio/grouppublishing.png" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/lebensfreude1.png,assets/img/portfolio/lebensfreude1.png,assets/img/portfolio/lebensfreude1.png','Lebensfreude','Vue Js','https://www.lebensfreude50.de/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/lebensfreude.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>lebensfreude</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/lebensfreude.png','Lebensfreude','Vue Js','https://www.lebensfreude50.de/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>



          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/fitbrix1.png,assets/img/portfolio/fitbrix1.png,assets/img/portfolio/fitbrix1.png','Fitbrix-Make your Business Count','.NET','https://www.fitbrix.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/fitbrix.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>Fitbrix</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/fitbrix.png','Fitbrix-Make your Business Count','.NET','https://www.fitbrix.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/freedom mortgage1.png,assets/img/portfolio/freedom mortgage1.png,assets/img/portfolio/freedom mortgage1.png','Freedommortgage','Vue Js','https://www.freedommortgage.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/freedom mortgage.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>Freedommortgage</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/freedom mortgage.png,assets/img/portfolio/freedom mortgage.png,assets/img/portfolio/freedom mortgage.png','Freedommortgage','Vue Js','https://www.freedommortgage.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/orbitz1.png,assets/img/portfolio/orbitz1.png,assets/img/portfolio/orbitz1.png','Orbitz','Java','https://www.orbitz.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/orbitz.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>orbitz</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/portfolio/orbitz.png,assets/img/portfolio/portfolio/orbitz.png,assets/img/portfolio/portfolio/orbitz.png','Orbitz','Java','https://www.orbitz.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/trakop1.png,assets/img/portfolio/trakop1.png,assets/img/portfolio/trakop1.png','Trakop','PHP','https://web.trakop.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/trakop.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>trakop</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/trakop.png,assets/img/portfolio/trakop.png,assets/img/portfolio/trakop.png','Trakop','PHP','https://web.trakop.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>


          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/trakop1.png,assets/img/portfolio/trakop1.png,assets/img/portfolio/trakop1.png','Yondo-Your All-In-One Solution for Offering Live Online Sessions & Videos On Demand','Java','https://www.yondo.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/yondo.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>yondo</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/yondo.png, assets/img/portfolio/yondo.png,assets/img/portfolio/yondo.png','Yondo-Your All-In-One Solution for Offering Live Online Sessions & Videos On Demand','Java','https://www.yondo.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" (click)="pageShow('assets/img/portfolio/synerdion1.png,assets/img/portfolio/synerdion1.png,assets/img/portfolio/synerdion1.png','Synedrion','.Net','https://www.rent-a-manager.com/')">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/synerdion.png" class="img-fluid" alt="">
              <a href="javascript:;"><div class="portfolio-info">
                <h4>Synedrion</h4>
                <p>Web</p>
                <div class="portfolio-links">
                  <a (click)="pageShow('assets/img/portfolio/synerdion.png,assets/img/portfolio/synerdion.png,assets/img/portfolio/synerdion.png','Synedrion','.Net','https://www.rent-a-manager.com/')" data-gall="portfolioGallery" class="venobox"></a>
                  <a href="javascript:;" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div></a>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Portfolio Section -->



<!-- ======= Clients Section ======= -->



<section id="clients1" class="clients">
  <div class="container">

    <div class="section-title mb-5" data-aos="fade-up">
      <h2> Technologies We Work On</h2>
      <p>We Make IT Happen Using</p>
    </div>

    <div class="row">

      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client12.png" class="img-fluid" alt="">
      </div> 

        <div class="col-lg-2 col-md-4 col-6" >
        <img src="assets/img/clients/client1.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6" >
        <img src="assets/img/clients/client2.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6" > 
        <img src="assets/img/clients/client3.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6" >
        <img src="assets/img/clients/client4.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6" >
        <img src="assets/img/clients/client5.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client6.png" class="img-fluid" alt="">
      </div>  
      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client7.png" class="img-fluid" alt="">
      </div> 
      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client8.png" class="img-fluid" alt="">
      </div> 
      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client9.png" class="img-fluid" alt="">
      </div> 
      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client10.png" class="img-fluid" alt="">
      </div> 
      <div class="col-lg-2 col-md-4 col-6">
        <img src="assets/img/clients/client11.png" class="img-fluid" alt="">
      </div> 
      
<!-- 
             <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
            [config]="slideConfigg" >
            <div ngxSlickItem *ngFor="let slide of slideset" class="slide">

              <div class="col-lg-2 col-md-4 col-6" data-aos="zoom-in" data-aos-delay="500">
              <img src="{{slide.img}}" class="img-fluid" alt="">
            </div>
            </div>
          </ngx-slick-carousel>  -->




    </div>

  </div>
</section><!-- End Clients Section -->




    <!-- ======= Testimonials Section ======= -->
    <section id="testimonials1" class="testimonials">
      <div class="container setcontainertestimonials" data-aos="zoom-in">
        <div class="quote-icon">
          <i class="bx bxs-quote-right"></i>
        </div>
     

        <ngx-slick-carousel class="carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig" >
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">

              <div class="testimonial-item">
                <p>
                   {{slide.message}}
                </p>
                <img src="{{slide.img}}" class="testimonial-img" alt="">
                <h3>{{slide.name}}</h3>
                <h4>{{slide.company_name}}</h4>
              </div>
        </div>
    </ngx-slick-carousel>


      </div>
    </section><!-- End Testimonials Section -->

    



    <!-- ======= Contact Section ======= -->
    <section id="contact1" class="contact section-bg">
      <div class="container">

        <div class="section-title mb-5">
          <h2>Contact</h2>
          <p>Have any questions? We'd love to hear from you.</p>
        </div>

        <div class="row">

          <div class="col-md-4">
            <div class="info d-flex flex-column justify-content-center" data-aos="fade-right">
              <div class="address">
                <i class="icofont-google-map"></i>
                <h4>Location:</h4>
                <p>E-253, Phase 8b Industrial area Mohali <br>Punjab, India - 160055</p>
              </div>
            </div>
          </div>

            <div class="col-md-4">
              <div class="info d-flex flex-column justify-content-center" data-aos="fade-right">
              <div class="email">
                <i class="icofont-envelope"></i>
                <h4>Email:</h4>
                <p>apply@mettlecrowsolutions.co.in</p>
              </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="info d-flex flex-column justify-content-center" data-aos="fade-right">
              <div class="phone">
                <i class="icofont-phone"></i>
                <h4>Call:</h4>
                <p>+91 9877640296</p>
              </div>
              </div>
              </div>
          </div>

      </div>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->

  <div *ngIf="!pageFlag">
    <main id="main">

      <!-- ======= Breadcrumbs ======= -->
      <section id="breadcrumbs" class="breadcrumbs" data-aos="fade-up">
        <div class="container">
  
          <div class="d-flex justify-content-between align-items-center">
              <div class="back button" (click)="close()">
              <!-- <div class="back button" (click)="close()"> -->
              <h2><a href="#portfolio1"><i class="bx bx-arrow-back"></i>Portfolio Details</a></h2>
           </div>
            <ol>
              <li><a href="/">Home</a></li> 
              <li>Portfolio Details</li>
            </ol>
          </div>

        
  
        </div>
      </section><!-- End Breadcrumbs -->
  
      <!-- ======= Portfolio Details Section ======= -->
      <section id="portfolio-details" class="portfolio-details" data-aos="fade-up" data-aos-delay="100">
        
        <div class="container">
          <div class="row">
          <div class="col-lg-8">
          <div class="portfolio-details-container">
  
          <!--   <div class="owl-carousel portfolio-details-carousel">
              <img src="{{image}}" class="img-fluid" alt="" *ngFor="let image of product_img">  
            </div> -->

           <div class="owl-carousel portfolio-details-carousel">
            <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
            [config]="slideConfigg" >
            <div ngxSlickItem *ngFor="let slide of product_img" class="slide">

              <div class="col-lg-12 col-md-12 col-12" data-aos="zoom-in" data-aos-delay="500">
              <img src="{{slide}}" class="img-fluid" alt="">
            </div>
            </div>
          </ngx-slick-carousel>
          </div>

          </div>
          </div>
  
          <div class="col-lg-4">
            <div class="portfolio-info">
              <h3>Project information</h3>
              <ul>
                <li><strong>Project Title</strong>: {{project_title}}</li>
                 <li><strong>Technology</strong>: {{technolgy}}</li>
                <li><strong>Project URL</strong>: <a href="{{project_url}}" target="_blank">{{project_url}}</a></li>
              </ul>
            </div>
          </div>
        </div>
  
        </div>
      </section><!-- End Portfolio Details Section -->
  
    </main><!-- End #main -->
  </div>

 
 <!-- ======= Footer ======= -->
 <div id="footer">

  <div class="footer-top">

    <div class="container">

      <div class="row justify-content-center">
        <div class="col-lg-6">
          <a href="#header" class="scrollto footer-logo"><img src="assets/img/crowlogo.png" alt=""></a>
          <h3>Mettle Crow Solutions</h3>
          <p>DELIVERING THE BEST IT SERVICES, WORLDWIDE</p>
        </div>
      </div>

    <!--   -->

    <div class="social-links">
      <a href="https://twitter.com/CrowMettle" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
      <a href="https://www.facebook.com/mettlecrow.s" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="skype:live:.cid.d6da6d21d085a840?chat" target="_blank" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="https://www.linkedin.com/in/mettle-crow-solutions-57242b206/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
    </div>

    </div>
  </div>

  <div class="container footer-bottom clearfix">
    <div class="copyright">
      &copy; Copyright <strong><span>Mettle Crow Solutions</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
    </div>
  </div>
</div><!-- End Footer -->
<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>

<router-outlet></router-outlet>
